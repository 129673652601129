import { writable } from 'svelte/store';
import { browser } from '$app/env';

export const openModalId = writable<string | null>(null);

let timeout: NodeJS.Timeout | undefined;

export const openModal = (modalId: string) => {
  // Prevent setting an open modal for all users!
  if (!browser) return;
  openModalId.update((currentModal) => {
    if (currentModal) {
      resetTimeout();
      timeout = setTimeout(() => openModal(modalId), 100);
      return null;
    }
    return modalId;
  });
};

function resetTimeout() {
  if (timeout) {
    clearTimeout(timeout);
    timeout = undefined;
  }
}

export const closeModal = () => {
  resetTimeout();
  openModalId.set(null);
};
